import { createAsyncThunk } from "@reduxjs/toolkit";
import { agent, encodeGetParams } from "../../../api";
import { apiUrlV1 } from "../../../config/config";
import { Service, Specialization, SpecializationOverview, AllServices, SingleService } from "../../../entities/Specialization";

interface LoadSystemSpecializationsParams {
  query: string,
}

export const loadSystemSpecializations = createAsyncThunk(
  'services/loadSystemSpecializations',
  async (params: LoadSystemSpecializationsParams) => {
    const { query } = params;
    const encodedParams = encodeGetParams({ search: query });

    const res = await agent.get(`${apiUrlV1}/catalog/system-specializations/overview/${encodedParams}`);

    if (res.status === 200) {
      const specializations: SpecializationOverview[] = (await res.json()).results;
      return specializations;
    }

    throw Error(`Couldn't load system specializations`);
  }
);


interface LoadSystemServicesParams {
  query?: string,
  id?: number,
  offset?: number,
}

export const loadSystemServices = createAsyncThunk(
  'catalog/loadSystemServices',
  async (params: LoadSystemServicesParams) => {
    const { query, id, offset } = params;
    let res;
    const limit = 20;
    
    if (query) {
      const encodedParams = encodeGetParams({ search: query });
      res = await agent.get(`${apiUrlV1}/catalog/system/services/${encodedParams}`);
    } else if (id) {
      res = await agent.get(`${apiUrlV1}/catalog/system/services/?system_specialization=${id}`);
    } else if (offset && limit) {
      res = await agent.get(`${apiUrlV1}/catalog/system/services/?limit=${limit}&offset=${offset}`);
    } else {
      res = await agent.get(`${apiUrlV1}/catalog/system/services/?limit=${limit}`);
    }

    if (res.status === 200) {
      const services: SingleService[] = (await res.json()).results;
      return services;
    }

    throw Error(`Couldn't load system services`);
  }
)

export const loadUserSpecializations = createAsyncThunk(
  'services/loadUserSpecializations',
  async () => {
    const res = await agent.get(`${apiUrlV1}/catalog/user-specializations/`);

    if (res.status === 200) {
      const specializations: SpecializationOverview[] = (await res.json()).results;
      return specializations;
    }

    throw Error(`Couldn't load user specializations`);
  }
);

export const loadUserServices = createAsyncThunk(
  'services/loadUserServices',
  async () => {
    const res = await agent.get(`${apiUrlV1}/catalog/user-services/`);

    if (res.status === 200) {
      const services: Service[] = (await res.json()).results;
      return services;
    }

    throw Error(`Couldn't load user services`);
  }
);

type LoadUserSpecializationParams = {
  userSpecializationId: number,
  systemSpecializationId?: number,
};

export const loadUserSpecialization = createAsyncThunk(
  'services/loadUserSpecialization',
  async ({ userSpecializationId, systemSpecializationId }: LoadUserSpecializationParams) => {
    
    const servicesParams = encodeGetParams({
      system_specialization: `${systemSpecializationId}`,
    });
    return Promise.all([
      agent.get(`${apiUrlV1}/catalog/user-specializations/${userSpecializationId}/`)
        .then(res => res.json())
        .then(body => body as Specialization),
      systemSpecializationId ? agent.get(`${apiUrlV1}/catalog/system-specializations/${systemSpecializationId}/`)
        .then(res => res.json())
        .then(body => body as Specialization) : Promise.resolve(undefined),
      systemSpecializationId ? agent.get(`${apiUrlV1}/catalog/system/services/${servicesParams}`)
        .then(res => res.json())
        .then(body => body.results as Service[]) : Promise.resolve([]),
    ]).then(([userSpecialization, systemSpecialization, systemServices]: [Specialization, Specialization | undefined, Service[]]) => {
      if (systemSpecialization) {
        systemSpecialization.services = systemServices;
      }
      return { userSpecialization, systemSpecialization };
    }).catch(e => {
      throw Error(`Couldn't load user/system specialization`);
    });
  }
)

type UpdateUserSpecializationsParams = {
  deleted: SpecializationOverview[],
  added: SpecializationOverview[],
};

export const updateUserSpecializations = createAsyncThunk(
  'services/updateUserSpecializations',
  async (params: UpdateUserSpecializationsParams, thunkApi) => {
    const { deleted, added } = params;

    const deletePromises = deleted.map(specialization => {
      return agent.delete(`${apiUrlV1}/catalog/user-specializations/${specialization.id}/`);
    });

    const addPromises = added.map(specialization => {
      return agent.post(`${apiUrlV1}/catalog/user-specializations/`, {
        body: JSON.stringify([{ systemSpecialization: specialization.id }])
      });
    });

    return await Promise.all([
      ...deletePromises,
      ...addPromises,
    ]).then(() => {
      thunkApi.dispatch(loadUserSpecializations());
    });
  }
)

type UserServiceParam = {
  userSpecialization: SpecializationOverview,
  service: Service,
}

const prepareServiceToParameters = (service: Service, userSpecialization: SpecializationOverview): any => {
  const s: any = { ...service };

  delete s.id;
  s.specialization = userSpecialization.id;

  delete s.images;
  s.imagesIds = service.images?.map(({ id }) => ({ id })) || [];

  return s;
}

type UserServiceParams = {
  service: SingleService,
}

const prepareServiceToSend = (service: SingleService): any => {
  const s: any = { ...service };

  delete s.images;
  s.imagesIds = service.images?.map(({ id }) => ({ id })) || [];

  return s;
}

export const addUserService = createAsyncThunk(
  'services/addUserService',
  async (params: UserServiceParams) => {
    const service: any = prepareServiceToSend(params.service);
    const res = await agent.post(`${apiUrlV1}/catalog/user-services-v2/`, {
      body: JSON.stringify(service),
    });

    if (res.status === 201) {
      return (await res.json()) as Service;
    }

    throw Error('error while adding user service');
  }
)

export const updateUserServices = createAsyncThunk(
  'services/updateUserServices',
  async (params: UserServiceParams) => {
    const service: any = prepareServiceToSend(params.service);
    const res = await agent.put(`${apiUrlV1}/catalog/user-services/${params.service.id}/`, {
      body: JSON.stringify(service),
    });

    if (res.status === 200) {
      return (await res.json()) as Service;
    }

    throw Error('error while adding user service');
  }
)

export const updateUserService = createAsyncThunk(
  'services/updateUserService',
  async (params: UserServiceParam) => {
    const { userSpecialization } = params;
    const service: any = prepareServiceToParameters(params.service, userSpecialization);
    service.images = service.imagesIds;

    const res = await agent.put(`${apiUrlV1}/catalog/user-services/${params.service.id}/`, {
      body: JSON.stringify(service),
    });

    if (res.status === 200) {
      return (await res.json()) as Service;
    }

    throw Error('error while adding user service');
  }
)

export const deleteUserService = createAsyncThunk(
  'services/deleteUserService',
  async (id: number) => {
    return agent.delete(`${apiUrlV1}/catalog/user-services-v2/${id}/`)
      .then(() => { return; });
  }
)

interface SearchUserServicesParams {
  searchText: string,
  userId?: number,
}

export const searchUserServices = createAsyncThunk(
  'services/searchUserServices',
  async (params: SearchUserServicesParams) => {
    // https://test.b2c.by/api/v1/catalog/user/services/?o=-popularity,-created_at
    const encodedGetParams = encodeGetParams({
      'o': '-popularity,-created_at',
      'limit': params.searchText.length >= 0 ? 5 : 10,
      'search': params.searchText,
      'of_user': true,
      'user_id': `${params.userId}`,
    });

    const res = await agent.get(`${apiUrlV1}/catalog/user/services/${encodedGetParams}`);

    if (res.status === 200) {
      const body = await res.json();
      return body.results as Service[];
    }

    throw Error(`Error searching services`);
  }
);

export const searchUserSpecializations = createAsyncThunk(
  'search/searchUserSpecializations',
  async (params: SearchUserServicesParams) => {
    const encodedGetParams = encodeGetParams({
      'include_services': true,
      'search': params.searchText,
    });

    const res = await agent.get(`${apiUrlV1}/catalog/user-specializations/${encodedGetParams}`);

    if (res.status === 200) {
      const body = await res.json();
      return body.results as Specialization[];
    }

    throw Error(`Error searching specializations`);
  }
)
