import { Box, Button, Card, CircularProgress, Typography, Link } from "@material-ui/core";
import { makeValidate, TextField, Checkboxes } from 'mui-rff';
import { useAuthStyles } from "./styles";
import * as React from "react";
import { GeocoderAddressAutocompleteRff } from "../../../common/GeocoderAddressAutocomplete/GeocoderAddressAutocomplete";
import { getCityGeocoder } from "../../../common/GeocoderAddressAutocomplete/geocoder";
import { Form, useForm, Field } from "react-final-form";
import * as Yup from 'yup';
import { SchemaOf } from "yup";
import { PlaceButton } from "../../../common/PlaceButton/PlaceButton";
import { getCityId, PlaceType } from "../../../entities/PlaceType";
import { useState } from "react";
import { apiUrlV1, countryCode } from "../../../config/config";
import { Gender } from "../../../entities/Gender";
import { useTranslation } from "react-i18next";
import { Role } from "../../../entities/UserProfile";
import { CreateSalonStepOne, CreateSalonStepThree, CreateSalonStepTwo } from "./CreateSalon";
import { ReactComponent as LogoIcon } from '../../../icons/logo.svg';


interface ProfileForm {
  firstName: string,
  lastName: string,
  email: string,
  gender: Gender,
  address: PlaceType,
  referralCode?: string
}

export function PositionField() {
  const form = useForm();

  return (
    <PlaceButton
      kind={"locality"}
      onPlaceChange={(place) => {
        form.change("address", place);
      }}
    />
  );
}

type ProfileEnterCardProps = {
  token: string,
  onProfileCreated: () => void,
  role: Role,
  phone?: string,
}


export const CreateProfileWorker = ({ token, onProfileCreated, role }: ProfileEnterCardProps) => {
  const { t } = useTranslation();
  const classes = useAuthStyles();
  const [loading, loadingSet] = useState<boolean>(false);

  const schema: SchemaOf<any> = Yup.object().shape({
    lastName: Yup.string().matches(/^([^0-9]*)$/, t('numbers-not-allowed')).min(2, ' Минимум 2 буквы').required(t('required')),
    firstName: Yup.string().matches(/^([^0-9]*)$/, t('numbers-not-allowed')).min(2, ' Минимум 2 буквы').required(t('required')),
    email: Yup.string().email('Некорректный email адрес'),
    terms: Yup.boolean().oneOf([true], 'Необходимо согласие').required('Необходимо согласие'),
    address: Yup
      .object<any>()
      .nullable()
      .required(t('required'))
      .test('addressInCountry', t('country-address-required'), (val) => {
        const place: PlaceType = val as PlaceType;
        if (!place) return true;
        return place?.country === countryCode;
      }),
    referralCode: Yup.string().test('testAsyncReferral', t("referra-code-error"),
      value => {
        if (!value) return true

        return fetch(`${apiUrlV1}/profiles/check/referral_code/${value}/`,
          {
            method: "GET",
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            }
          }).then(
            async res => {
              return res.status === 200
            }
          )
      }
    )
  })

  const validate = makeValidate(schema);

  async function onSubmit(values: ProfileForm) {
    loadingSet(true);
    try {
      const cityId = await getCityId(values.address);
      const profileData = {
        role,
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        referral_code: values.referralCode,
        city_id: cityId,
        gender: '',
      };
      if (!values.referralCode) {
        delete profileData['referral_code']
      }

      const res = await fetch(`${apiUrlV1}/profiles/complete-registration/`, {
        method: "PATCH",
        body: JSON.stringify(profileData),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      });
      
      if (res.status === 200) {
        onProfileCreated();
        loadingSet(false);
      }
    } catch (e) {
      loadingSet(false);
      throw Error("Cannot register the user!");
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{}}
      validate={validate}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.workerForm} >
            <TextField
              name="lastName"
              label={t('last-name')}
              required={true}
              inputProps={{ autoFocus: true }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              name="firstName"
              label={t('first-name')}
              required={true}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              name="email"
              label={"Email"}
              required={true}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              name="referralCode"
              label={t('referral-code')}
              InputLabelProps={{ shrink: true }}
            />

            <Box display={'flex'} flexDirection={'row'}>
              <Box flex={1}>
                <GeocoderAddressAutocompleteRff
                  autocompleteProps={{
                    name: 'address',
                    label: t('city'),
                    placeholder: t('city-enter-prompt'),
                    required: true,
                    textFieldProps: {
                      InputLabelProps: { shrink: true }
                    }
                  }}
                  geocoderGetter={getCityGeocoder}
                />
              </Box>
              <Box p={1} paddingTop={'22px'} paddingBottom={0} paddingRight={0}>
                <PositionField />
              </Box>
            </Box>
            <Checkboxes
              name="terms"
              required
              data={{
                label: (
                  <span>
                    Я даю{" "}
                    <Link href="https://b2c.by/public-contract" style={{textDecoration: 'underline'}}>
                      СОГЛАСИЕ
                    </Link> 
                    {" "}на обработку персональных данных.
                  </span>
                ),
                value: true,
              }}
            />
            <Button variant={"contained"} onClick={handleSubmit}>
              {loading && <CircularProgress size={20} />}
              {t('further')}
            </Button>
          </div>
        </form>
      )}
    />
  )
}

interface CreateProfileSalonProps {
  step: number,
  onNext: (step: number) => void,
  onBackStep: () => void,
  onProfileCreated: () => void,
  token: string,
  role: Role,
  phone?: string
}

const CreateProfileSalon = ({ step, token, role, phone, onNext, onBackStep, onProfileCreated }: CreateProfileSalonProps) => {
  const [salon, setSalon] = useState({
    name: '',
    email: '',
    address: '',
    profession: 1,
    salons_number: 1,
    employees_number: 1,
    features: []
  })
  const [loading, loadingSet] = React.useState<boolean>(false);


  const stepSubmit = (values: any, step: number) => {
    if (step === 3) {
      onSubmit({ ...salon, ...values })
    } else {
      setSalon({ ...salon, ...values });
      onNext(step);
    }
  }
  async function onSubmit(values: any) {
    loadingSet(true);

    try {
      const cityId = await getCityId(values.address);

      const salonData = {
        ...values,
        city: cityId
      }

      const resSalon = await fetch(`${apiUrlV1}/salon/salon-registration/`, {
        method: "POST",
        body: JSON.stringify(salonData),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
      if (resSalon.status === 200) {
        onProfileCreated();
        loadingSet(false);
      }
    } catch (e) {
      loadingSet(false);
      throw Error("Cannot register the user!");
    }
  }

  return (
    <>
      {
        step === 0 &&
        <CreateSalonStepOne
          initial={{
            name: salon.name,
            email: salon.email,
            address: salon.address
          }}
          onSubmit={(value) => { stepSubmit(value, 1) }}
        />
      }
      {

        step === 1 && <>
          <Typography variant="h1">Несколько вопросов о вашем бизнесе</Typography>
          <Typography variant="body1">
            Эти данные помогут нам лучше настроить платформу для ваших потребностей
          </Typography>
          <CreateSalonStepTwo
            initial={{
              profession: salon.profession,
              salons_number: salon.salons_number,
              employees_number: salon.employees_number
            }}
            onSubmit={(value) => { stepSubmit(value, 2) }}
            onBack={onBackStep}
          />
        </>
      }
      {
        step === 2 && <>
          <Typography variant="h1">Как вы планируете использовать нашу платформу?</Typography>
          <Typography variant="body1">
            Наши инструменты позволяют решать множество потребностей. Выберите то, что вам интересно
          </Typography>
          <CreateSalonStepThree
            initial={salon.features}
            onSubmit={(value) => { stepSubmit(value, 3) }}
            onBack={(value) => { stepSubmit(value, 1) }}
            loading={loading}
          />
        </>
      }
    </>
  )
}


export const ProfileEnterCard = ({ token, onProfileCreated, role, phone }: ProfileEnterCardProps) => {
  const classes = useAuthStyles();
  const { t } = useTranslation();
  const [typeForm, setTypeForm] = useState<string>('worker')
  const [stepAuthSalon, setStepAuthSalon] = useState<number>(0)

  const typeFormHandler = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === 'worker') {
      setStepAuthSalon(0)
    }
    if (value === 'salon') {
      setStepAuthSalon(0)
    }
    setTypeForm(value)

  }
  const onNextStep = (steps: number) => {
    setStepAuthSalon(steps)
  }

  const onBackStep = () => {
    setStepAuthSalon(stepAuthSalon - 1)
  }

  return (
    <div className={classes.container}>
      <Card classes={{ root: classes.card }} style={{ overflow: 'inherit' }}>
        {stepAuthSalon === 0 &&
          <Typography variant="h1">Регистрация</Typography>
        }
        {
          typeForm === 'worker' &&
          <CreateProfileWorker
            token={token}
            onProfileCreated={onProfileCreated}
            role={role} />
        }
        {/* test logo for new disign */}
        <div style={{
          position: 'absolute',
          bottom: -50,
          left: '50%',
          transform: 'translateX(-50%)'
        }}>
          <LogoIcon />
        </div>
      </Card>
    </div>
  );
};

export default ProfileEnterCard;