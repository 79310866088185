import React from 'react';
import ReactDOM from 'react-dom';
import {MastersDashboardApp} from './MastersDashboardApp';
import reportWebVitals from '../reportWebVitals';
import '../i18n';
import '../importWebFonts';

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  // Код Google Tag Manager
  const script = document.createElement('script');
  script.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MC9LHQZ4');
  `;
  document.head.appendChild(script);
  
  const noscript = document.createElement('noscript');
  noscript.innerHTML = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MC9LHQZ4"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.prepend(noscript);
  }

ReactDOM.render(
  <MastersDashboardApp/>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
