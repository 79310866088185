import { combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import authReducer from './auth/authStore';
import scheduleReducer from './schedule/scheduleStore';
import { profileReducer } from "./profile/profileStore";
import { servicesReducer } from './services/servicesStore';
import { workplacesReducer } from "./workplaces/workplacesStore";
import { clientsReducer } from "./clients/clientsStore";
import { eventsReducer } from "./events/eventsStore";
import { subscriptionReducer } from "./subscription/subscriptionStore";
import logger from 'redux-logger';
import { statisticsReducer } from "./statistics/statisticsStore";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { salonsReducer } from './salons/salonStore';
import { dashboardReducer } from './salons/dashboardStore';
import { consumablesReducer } from './consumables/consumablesStore';
import { tariffReducer } from './tariffs/tariffsStore';
import { messengersReducer } from './messengers/messengersStore'

const combinedReducer = combineReducers({
  auth: authReducer,
  schedule: scheduleReducer,
  profile: profileReducer,
  services: servicesReducer,
  workplaces: workplacesReducer,
  clients: clientsReducer,
  events: eventsReducer,
  statistics: statisticsReducer,
  subscription: subscriptionReducer,
  salons: salonsReducer,
  dashboard: dashboardReducer,
  consumables: consumablesReducer,
  tariff: tariffReducer,
  messengers: messengersReducer,
});

type RootStateExtract<R> = R extends Reducer<infer State> ? State : never;
export type RootState = RootStateExtract<typeof combinedReducer>;

const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === 'logout') {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => process.env.NODE_ENV === 'development'
    ? getDefaultMiddleware().concat(logger)
    : getDefaultMiddleware()
});

export type AppDispatch = typeof store.dispatch;

export default store;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppDispatch = () => useDispatch<AppDispatch>();
