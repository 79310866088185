import { Button, Card, Typography, CircularProgress } from "@material-ui/core";
import { useAuthStyles } from "./styles";
import { SmsCodeInput } from "./SmsCodeInput";
import React, { useCallback, useEffect, useState } from "react";
import { useOnEnterKeyPress } from "../../../hooks/useOnEnterKeyPress";
import { apiUrlV1 } from "../../../config/config";
import { agent } from "../../../api";
import { SendCodeTimeout } from "../../../common/SendCodeTimeout/SendCodeTimeout";
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next";
import { Role } from "../../../entities/UserProfile";
import { ReactComponent as LogoIcon } from '../../../icons/logo.svg';
import { B2CFormControlLabel, B2CRadio, B2CRadioGroup } from "src/common/RadioButtons/RadioButtons";

const CODE_LENGTH = 4;

type SmsCodeVerifyCardProps = {
  onCodeValidEnter: (token: string, refreshToken: string, userId: number) => void,
  // onCheckWorker: (body: CheckWorkerRole) => void,
  phoneNumber?: string,
  role: Role,
};

type bodyRole = {
  isExists: boolean,
  salonRole: string | null
}
export type CheckWorkerRole = {
  worker: bodyRole,
  salonWorker: bodyRole
}

export const SmsCodeVerifyCard = ({ onCodeValidEnter, phoneNumber, role }: SmsCodeVerifyCardProps) => {
  const classes = useAuthStyles();
  const [timeStart, timeStartSet] = useState(+ new Date())
  const [step, setStep] = useState<'sms' | 'chenge'>('sms')
  const [thisRole, setThisRole] = useState('worker')
  const [code, codeSet] = useState('');
  const [error, errorSet] = useState<string | null>(null);
  const [loading, loadingSet] = useState(false);
  const { t } = useTranslation();
  const [checkWorker, checkWorkerSet] = useState<CheckWorkerRole>(
    {
      worker: {
        isExists: false,
        salonRole: null
      },
      salonWorker: {
        isExists: false,
        salonRole: null
      },
    }
  )

  const resultCheckWorker: 'change' | 'notChange' = React.useMemo(() => {
    if (checkWorker.worker.isExists && checkWorker.salonWorker.isExists) {
      return 'change'
    }
    if (checkWorker.worker.isExists && !checkWorker.salonWorker.isExists) {
      return 'notChange'
    }
    if (!checkWorker.worker.isExists && !checkWorker.salonWorker.isExists) {
      return 'notChange'
    }
    if (!checkWorker.worker.isExists && checkWorker.salonWorker.isExists) {
      return 'change'
    }
    return 'change'
  }, [checkWorker])

  const receiveSms = useCallback(async () => {
    timeStartSet(+ new Date());

    const res = await agent.post(`${apiUrlV1}/otp/send-sms/`, {
      body: JSON.stringify({
        phone: `+375${phoneNumber}`
      }),
    });

  }, [timeStartSet, phoneNumber]);

  const checkAuthMethod = useCallback(async () => {
    try {
      const res = await agent.post(`${apiUrlV1}/profiles/check-auth-method-by-phone/`, {
        body: JSON.stringify({
          phone: `+375${phoneNumber}`
        })
      })
      if (res.status === 200) {
        const body = await res.json()
        checkWorkerSet(body)
      }
    } catch (error) {
      console.error(error);
    }
  }, [])

  useEffect(() => {
    receiveSms();
    checkAuthMethod()
  }, [receiveSms, checkAuthMethod]);

  const onCodeChange = useCallback((code: string) => {
    codeSet(code);
  }, [codeSet]);

  const onClick = useCallback(() => {
    if (resultCheckWorker === 'change') {
      setStep('chenge')
    }
    if (resultCheckWorker === 'notChange') {
      onOkClick('worker')
    }
  }, [resultCheckWorker, code])

  const onOkClick = useCallback((role) => {
    loadingSet(true);

    fetch(`${apiUrlV1}/profiles/auth-by-phone/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone: `+375${phoneNumber}`,
        otp_code: code,
        role,
      })
    }).then(res => {
      if (res.status === 457) {
        throw new Error(t('wrong-code'));
      } else if (res.status >= 400) {
        throw new Error(t('error-contact-support'));
      }

      return res.json();
    }).then(response => {
      const { token, refresh, user } = response;
      loadingSet(false);
      onCodeValidEnter(token, refresh, user);
    }).catch((e: Error) => {
      errorSet(e.message);
      loadingSet(false);
    });
  }, [code, phoneNumber, onCodeValidEnter, loadingSet]);

  const onChangeRole = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === 'worker') {
      setThisRole('worker')
    }
    if (value === 'salon_worker') {
      setThisRole('salon_worker')
    }
  }

  const okButtonDisabled = !(code.length === CODE_LENGTH);

  const onEnterPress = useOnEnterKeyPress(() => {
    if (!okButtonDisabled && !loading) {
      onClick();
    }
  }, []);

  return (
    <div className={classes.container}>
      {
        step === 'sms' &&
        <Card onKeyPress={onEnterPress} classes={{ root: classes.card }} style={{ overflow: 'inherit' }}>
          <Typography variant="h1">{t('code')}</Typography>
          <div>
            <Typography display={"inline"}>
              {t('we-sent-sms')}{' '}
            </Typography>
            <Typography display={"inline"}>
              <NumberFormat
                value={`+375${phoneNumber}`}
                displayType="text"
                format="+### ## ###-##-##"
                mask={'_'}
                prefix="+375"
              />
            </Typography>
          </div>
          <SmsCodeInput length={CODE_LENGTH} onCodeChange={onCodeChange} />
          <SendCodeTimeout
            timeStartMilliseconds={timeStart}
            onResendCodeClick={receiveSms}
          />
          <Typography color="error">{!!error && `${error}`}</Typography>
          <Button variant={"contained"} disabled={okButtonDisabled} onClick={onClick}>
            {loading && <CircularProgress size={20} />}
            {t('further')}
          </Button>
          {/* test logo for new disign */}
          <div style={{
            position: 'absolute',
            bottom: -50,
            left: '50%',
            transform: 'translateX(-50%)'
          }}>
            <LogoIcon />
          </div>
        </Card>
      }
      {
        step === 'chenge' &&
        <Card onKeyPress={onEnterPress} classes={{ root: classes.card }} style={{ overflow: 'inherit' }}>
          <Typography variant="h1">{t('auth')}</Typography>
          <div>
            <Typography display={"inline"}>
              {t('we-change-role')}
            </Typography>
          </div>
          <B2CRadioGroup
            row
            value={thisRole}
            onChange={onChangeRole}
          >
            <B2CFormControlLabel
              value={'worker'}
              label={'Мастер'}
              control={<B2CRadio />}
            />
            <B2CFormControlLabel
              value={'salon_worker'}
              label={'Салон'}
              control={<B2CRadio />}
            />
          </B2CRadioGroup>
          <Button variant={"contained"} disabled={okButtonDisabled} onClick={() => { onOkClick(thisRole) }}>
            {loading && <CircularProgress size={20} />}
            {t('further')}
          </Button>
          {/* test logo for new disign */}
          <div style={{
            position: 'absolute',
            bottom: -50,
            left: '50%',
            transform: 'translateX(-50%)'
          }}>
            <LogoIcon />
          </div>
        </Card>
      }
    </div>
  );
}
